import React, { useContext } from "react";
import styled from "styled-components";

import { graphql } from "gatsby";

import SEO from "../components/seo";
import Layout from "../components/layout";
import Service from "../components/page-components/service";

import { WebsiteContext } from "../context/WebsiteContext";
import SingleBrand from "../components/page-components/single-brand";

const BrandTemplateContainer = styled.div`
  padding-top: ${props => props.distanceFromTop + "px"};
`;

const BrandTemplate = ({ data }) => {
  let page = data?.brand?.edges[0]?.node || {};
  const { distanceFromTop } = useContext(WebsiteContext);
  return (
    <Layout>
      <SEO title={page.title} />
      <BrandTemplateContainer distanceFromTop={distanceFromTop}>
        <SingleBrand data={data} />
      </BrandTemplateContainer>
    </Layout>
  );
};

export default BrandTemplate;

export const query = graphql`
  query($slug: String!) {
    brand: allWpBrand(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          slug
          content
          title
          brand_acf {
            brandBannerSettings {
              bannerHeadline
              bannerImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 90
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
            brandImageGridType
            brandImageGrid {
              brandImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 90
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
