import React, { useRef } from "react";
import styled from "styled-components";
import ServiceBanner from "../service/service-banner";

import Wrapper from "../../elements/wrapper";

import { media } from "../../helpers";
import BrandsWeOwn from "../home/brands-we-own";
import BrandsWeWorkWith from "../home/brands-we-work-with";
import BrandImageGrid from "./brand-image-grid";

const SingleBrandContainer = styled.div`
  .brand-content-container {
    padding: 70px 0;
    ${media.medium`max-width: 750px;`}

    p {
      font-weight: 300;
      font-size: 16px;
    }

    ul {
      list-style: none;
      padding: 20px 0;
      margin: 0;
      li {
        font-weight: bold;
      }
    }
  }
`;

const SingleBrand = ({ data }) => {
  let page = data?.brand?.edges[0]?.node || {};
  const { content } = page || {};
  const {
    brandBannerSettings,
    brandsWeOwnSection,
    brandsWeWorkWithSection,
    brandImageGrid,
    brandImageGridType,
  } = page?.brand_acf || {};

  const contentRef = useRef(null);

  const isBrowser = typeof window != "undefined";

  function handleScrollToNextSection() {
    let scrollPoint = contentRef.current.offsetTop - 130;

    if (isBrowser) {
      window.scrollTo({ top: scrollPoint, behavior: "smooth" });
    }
  }

  return (
    <SingleBrandContainer>
      <ServiceBanner
        settings={brandBannerSettings}
        handleScrollToNextSection={handleScrollToNextSection}
      />
      <Wrapper className="brand-content-container">
        <div
          ref={contentRef}
          className="inner-wrap"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </Wrapper>
      <BrandImageGrid className={brandImageGridType} rows={brandImageGrid} />
      {/* <BrandsWeOwn settings={brandsWeOwnSection} />
      <BrandsWeWorkWith settings={brandsWeWorkWithSection} /> */}
    </SingleBrandContainer>
  );
};

export default SingleBrand;
