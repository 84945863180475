import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import Wrapper from "../../elements/wrapper";

import { media, TransitionMixin } from "../../helpers";

const BrandsWeWorkWithContainer = styled.div`
  padding: 100px 0;
  .brands-we-work-with-wrapper {
    .title-container {
      text-align: center;
      padding-bottom: 70px;
      ${media.large`padding-bottom: 100px;`}
      h2 {
        font-weight: 900;
        margin: 0;
        text-align: center;
        text-transform: uppercase;
        color: #222;
        font-size: 32px;
        ${media.medium`font-size: 42px;`}
      }
    }
    .icons-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-bottom: 50px;
      ${media.large`max-width: 1080px;`}

      .item-container {
        margin-bottom: 40px;
        flex: 0 0 33.33%;
        ${media.small`flex: 0 0 25%;`}
        ${media.medium`flex: 0 0 20%;`}
        ${media.large`flex: 0 0 16.666%;`}

        .inner-wrap {
          max-width: 100px;
          margin: 0 auto;
        }
      }
    }
  }
  .btn-container {
    text-align: center;
    a {
      color: #222;
      text-decoration: none;
      border: 2px solid #222;
      padding: 15px 10px;
      min-width: 260px;
      display: inline-block;
      text-transform: uppercase;
      line-height: 1;
      ${TransitionMixin(".25s")};
      &:hover {
        background-color: #222;
        color: #fff;
      }
    }
  }
`;

const BrandsWeWorkWith = ({ settings }) => {
  const { sectionTitle, brandIcons, bottomButton } = settings || {};
  return (
    <BrandsWeWorkWithContainer>
      <Wrapper className="brands-we-work-with-wrapper">
        <div className="title-container">
          <h2>{sectionTitle}</h2>
        </div>
        <Wrapper className="icons-container">
          {brandIcons &&
            brandIcons.map((icon, iconIndex) => {
              let iconImage = getImage(icon?.brandIcon?.localFile);
              return (
                <div className="item-container" key={iconIndex}>
                  <div className="inner-wrap">
                    <div className="image-container">
                      <GatsbyImage
                        alt={`Icon ` + iconIndex}
                        image={iconImage}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </Wrapper>
        <div className="btn-container">
          <Link to={bottomButton?.url}>{bottomButton?.title}</Link>
        </div>
      </Wrapper>
    </BrandsWeWorkWithContainer>
  );
};

export default BrandsWeWorkWith;
