import React, { useRef } from "react";
import styled from "styled-components";
import Wrapper from "../../elements/wrapper";
import { media } from "../../helpers";

import ServiceBanner from "./service-banner";
import Offerings from "../about/offerings";

const ServiceContainer = styled.div`
  .service-content-container {
    padding: 70px 0;
    ${media.medium`max-width: 750px;`}
    ${media.large`max-width: 1080px;`}

    p {
      font-weight: 300;
    }

    ul {
      list-style: none;
      padding: 20px 0;
      margin: 0;
      li {
        font-weight: bold;
      }
    }
  }
`;

const Service = ({ data }) => {
  let page = data?.service?.edges[0]?.node || {};
  const { content } = page || {};
  const { serviceBannerSettings, serviceOfferingsSettings } =
    page?.service_acf || {};

  const contentRef = useRef(null);

  const isBrowser = typeof window != "undefined";

  function handleScrollToNextSection() {
    let scrollPoint = contentRef.current.offsetTop - 130;

    if (isBrowser) {
      window.scrollTo({ top: scrollPoint, behavior: "smooth" });
    }
  }
  return (
    <ServiceContainer>
      <ServiceBanner
        settings={serviceBannerSettings}
        handleScrollToNextSection={handleScrollToNextSection}
      />
      <Wrapper className="service-content-container">
        <div
          ref={contentRef}
          className="inner-wrap"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </Wrapper>
      {serviceOfferingsSettings && (
        <Offerings caption={true} settings={serviceOfferingsSettings} />
      )}
    </ServiceContainer>
  );
};

export default Service;
