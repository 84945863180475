import React from "react";
import styled from "styled-components";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import Wrapper from "../../elements/wrapper";
import { media } from "../../helpers";

const BrandImageGridContainer = styled.div`
  .brand-image-wrapper {
    ${media.small`display: flex;flex-wrap: wrap; margin: 0 -40px;margin-bottom: -30px;`}

    .image-container {
      flex: 0 0 33.33%;
      overflow: hidden;
      margin-bottom: 80px;
      display: flex;

      ${media.small`max-height: 280px; padding: 0 40px;`}
      ${media.medium`max-height: 350px;`}
      ${media.large`max-height: 400px;`}
      ${media.xl`max-height: 650px;`}

      .gatsby-image-wrapper {
        flex: 1;
      }
      &:nth-child(4n) {
        /* or 4n+1 */
        flex: 1 1 100%;
      }
    }
  }

  &.alt {
    .brand-image-wrapper {
      .image-container {
        &:nth-child(1) {
          flex: 0 0 35%;
        }
        &:nth-child(2) {
          flex: 0 0 65%;
        }
        &:nth-child(3) {
          flex: 0 0 65%;
        }
        &:nth-child(4) {
          flex: 0 0 35%;
        }
        &:nth-child(5) {
          flex: 1 1 100%;
        }
      }
    }
  }
`;

const BrandImageGrid = ({ rows, className }) => {
  return (
    <BrandImageGridContainer className={className}>
      <Wrapper className="brand-image-container">
        <div className="brand-image-wrapper">
          {rows.map((row, rowIndex) => {
            let image = getImage(row?.brandImage?.localFile || null);
            return (
              <div className="image-container" key={rowIndex}>
                <GatsbyImage image={image} />
              </div>
            );
          })}
        </div>
      </Wrapper>
    </BrandImageGridContainer>
  );
};

export default BrandImageGrid;
