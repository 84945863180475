import React, { useContext } from "react";
import styled from "styled-components";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import Wrapper from "../../elements/wrapper";
import { media } from "../../helpers";
import { WebsiteContext } from "../../../context/WebsiteContext";
import ChevronDown from "../../../images/chevron-down.inline.svg";

const ServiceBannerContainer = styled.div`
  .image-container {
    max-height: calc(100vh - ${props => props.distanceFromTop + "px"});
    overflow: hidden;
    position: relative;

    .gatsby-image-wrapper {
      min-height: 50vh;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 50%);
      display: flex;
      align-items: center;
      justify-content: center;

      h1 {
        color: #fff;
        margin: 0;
        font-weight: 900;
        font-size: 32px;
        text-transform: uppercase;
        text-align: center;
        padding: 0 20px;
        ${media.medium`font-size: 42px;`}
      }
    }

    .footer-btn {
      position: absolute;
      left: 0;
      bottom: 0px;
      width: 100%;
      text-align: center;

      button {
        line-height: 1;
        background-color: transparent;
        border: none;
        padding: 0;

        &:focus,
        &:active {
          outline: none;
        }

        svg {
          width: 60px;
          height: 60px;
          stroke: #fff;
          stroke-width: 1px;
        }
      }
    }
  }
`;

const ServiceBanner = ({ settings, handleScrollToNextSection }) => {
  const { bannerHeadline, bannerImage } = settings || {};
  let image = getImage(bannerImage?.localFile);

  const { distanceFromTop } = useContext(WebsiteContext);

  return (
    <ServiceBannerContainer distanceFromTop={distanceFromTop}>
      <div className="image-container">
        <GatsbyImage alt={bannerHeadline + " banner image"} image={image} />
        <div className="overlay">
          <h1>{bannerHeadline}</h1>
        </div>
        <div className="footer-btn">
          <button onClick={() => handleScrollToNextSection()}>
            <ChevronDown />
          </button>
        </div>
      </div>
    </ServiceBannerContainer>
  );
};

export default ServiceBanner;
